<template>
  <div class="home-quality">
    <h2>Quality</h2>
    <UiTitleLine />
    <h3>高正商店のこだわり</h3>

    <div class="outer">
      <div class="back-img-og">
        <div class="wrap">
          <div class="quality-box">
            <div class="text-cover1">
              <img
                :src="`${rootUrl}/img/takamasa_top_niko001.png`"
                alt="No1"
                class="number"
              />
              <div class="quality-content1">
                <p class="sub-title">
                  世界中からおいしい商品を<br />揃えています
                </p>
                <p class="detail">
                  世界中のドライフルーツやナッツを実際に食べ比べ、「おいしい」「価格もお値打ち」と評価した商品だけを高正商店では取り扱っています。
                </p>
              </div>
            </div>
            <div class="img-cover">
              <img
                :src="`${rootUrl}/img/takamasa_top013.jpg`"
                alt="畑イメージ"
                class="big-img"
              />
              <div class="sp-img-cover">
                <img
                  :src="`${rootUrl}/img/takamasa_top014.jpg`"
                  alt="トルコ"
                  class="mini-img"
                />

                <img
                  :src="`${rootUrl}/img/takamasa_top015.jpg`"
                  alt="クランベリー"
                  class="mini-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- No2  -->
      <div class="back-img-be">
        <div class="wrap">
          <div class="quality-box-n02">
            <div class="img-cover-n02">
              <img
                :src="`${rootUrl}/img/takamasa_top016.jpg`"
                alt="いちじく"
                class="big-img"
              />

              <img
                :src="`${rootUrl}/img/takamasa_top017.jpg`"
                alt="ピーカンナッツ"
                class="mini-img"
              />

              <img
                :src="`${rootUrl}/img/takamasa_top018.jpg`"
                alt="マカデミアナッツ"
                class="mini-img"
              />
            </div>
            <div class="text-cover2">
              <img
                :src="`${rootUrl}/img/takamasa_top_niko002.png`"
                alt="No2"
                class="number"
              />
              <div class="quality-content2">
                <p class="sub-title">お値打ち価格で<br />ご提供しています</p>
                <p class="detail">
                  高正商店で取り扱っている商品は、お値打ち価格です。おいしい商品を、普段からいっぱい食べていただきたい。このような思いから、なるべく安く商品をお客様へ提供する努力をしています。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- No3  -->
      <div class="back-img-og">
        <div class="wrap">
          <div class="quality-box">
            <div class="text-cover2">
              <img
                :src="`${rootUrl}/img/takamasa_top_niko003.png`"
                alt="No3"
                class="number"
              />
              <div class="quality-content2">
                <p class="sub-title">豊富な<br />商品ラインナップ</p>
                <p class="detail">
                  高正商店では、ドライフルーツ全10種類・全30商品、ナッツ全14種類・全41商品、フルーツティー全6種類・全14商品を取り扱っています。豊富な商品ラインナップだから、好みの商品が見つかるし、食べ飽きません。
                </p>
              </div>
            </div>
            <div class="img-cover-n02">
              <img
                :src="`${rootUrl}/img/takamasa_top019.jpg`"
                alt="アーモンド"
                class="big-img"
              />

              <img
                :src="`${rootUrl}/img/takamasa_top020.jpg`"
                alt="プルーン"
                class="mini-img"
              />

              <img
                :src="`${rootUrl}/img/takamasa_top021.jpg`"
                alt="フルーツティー"
                class="mini-img"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- No4  -->
      <div class="back-img-be">
        <div class="wrap">
          <div class="quality-box">
            <div class="img-cover">
              <img
                :src="`${rootUrl}/img/takamasa_top022.jpg`"
                alt="工場"
                class="big-img"
              />
            </div>
            <div class="text-cover1">
              <img
                :src="`${rootUrl}/img/takamasa_top_niko004.png`"
                alt="No4"
                class="number"
              />
              <div class="quality-content1">
                <p class="sub-title">
                  提携工場で安心・安全に<br />こだわって手作業で袋詰め
                </p>
                <p class="detail">
                  高正商店は、提携工場で一品ずつ人の手作業で愛情を込めて製造・袋詰めしています。手作業だからできる、商品の品質と安心・安全へのこだわりが自慢です。
                </p>
              </div>
            </div>

            <div class="img-cover-n02">
              <img
                :src="`${rootUrl}/img/takamasa_top023.jpg`"
                alt="出荷風景"
                class="mini-img"
                style="margin-left: 0"
              />
              <img
                :src="`${rootUrl}/img/takamasa_top024.jpg`"
                alt="出荷風景"
                class="mini-img"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- No5 -->
      <div class="back-img-og">
        <div class="wrap">
          <div class="quality-box">
            <div class="text-cover1">
              <img
                :src="`${rootUrl}/img/takamasa_top_niko005.png`"
                alt="No5"
                class="number"
              />
              <div class="quality-content1">
                <p class="sub-title">新鮮な商品をお届け</p>
                <p class="detail">
                  高正商店は、お客様からご注文をいただいてから、提携工場で製造して、発送しています。倉庫で眠っている在庫品ではなく、注文後に製造した新鮮な商品をお届けしています。
                </p>
              </div>
            </div>
            <div class="img-cover-n02">
              <img
                :src="`${rootUrl}/img/takamasa_top025.jpg`"
                alt="製造工程1"
                class="mini-img2 m-r"
              />

              <img
                :src="`${rootUrl}/img/takamasa_top026.jpg`"
                alt="製造工程1"
                class="mini-img2 m-r"
              />

              <img
                :src="`${rootUrl}/img/takamasa_top027.jpg`"
                alt="製造棚"
                class="big-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTitleLine from "@/components/Ui/TitleLine.vue";

export default {
  name: "HomeQuality",
  components: {
    UiTitleLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

.back-img-og,
.back-img-be {
  .quality-box,.quality-box-n02 {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    height: 250px;


    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
    .text-cover1,
    .text-cover2 {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1024px) {
        // display: block;
        flex-direction: column;  
        width: 100%; 
          }

      .number {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin-right: 10px;
        @media screen and (max-width: 1024px){
        margin: 0 0 10px 0;

        }
      }
      .quality-content1,
      .quality-content2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .sub-title {
          font-size: 25px;
          font-weight: bold;
          line-height: 1.2em;
          margin-bottom: 8px;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }
        .detail {
          line-height: 1.4em;
          @media screen and (max-width: 1024px) {
          padding-bottom: 15px;

          }
        }
      }
      .quality-content1 {
        width: 77%;
        @media screen and (max-width: 1024px){
          width:100%;}
      }
      .quality-content2 {
        width: 80%;
          @media screen and (max-width: 1024px){
          width:100%;}
      }
    }
    .text-cover1 {
      width: 44%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 0;
      }
    }
    .text-cover2 {
      width: 55%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 0;
      }
    }
    .img-cover,.img-cover-n02 {
      display: flex;
      
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
      }

      .sp-img-cover {
        display: flex;
        @media screen and (max-width: 1024px) {
          justify-content: space-between;
        }
      }

      .big-img {
        align-self: flex-start;
        height: 100%;
        // object-fit: cover;
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      .mini-img,
      .mini-img2 {
        margin-left: 10px;
        align-self: flex-start;
        height: 100%;
         @media screen and (min-width:768px) and ( max-width:1024px){
           width: 30%;
         }
        @media screen and (max-width: 1024px){
              width: 47%;
              margin: 0;
        }
      }
      .mini-img2 {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .img-cover-n02{
      @media screen and (max-width: 1024px){
        flex-direction: initial;
        justify-content: space-between;
      }
    }
  }
  .quality-box{
  @media screen and (max-width: 1024px){
        display: block;
  }
}
.quality-box-n02{
  @media screen and (max-width: 1024px){
    flex-flow: wrap-reverse;
    width: 100%;
  }

}
.m-r{
  @media screen and (max-width: 1024px){
    margin-right: 0px!important;
  }
}
}

.back-img-og {
  background-color: var(--orange);
  &:first-child {
    margin-top: 50px;
  }
}
.back-img-be {
  background-color: var(--beige);
  
}
</style>
