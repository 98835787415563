<template>
  <div class="category-product">
    <div class="wrap">
      <div class="cover">
        <div class="product-title">
          <h1>{{ productData.productName }}</h1>
        </div>

        <img
          :src="`${rootUrl}/img/takamasa-${categoryId}-${productData.productId}.jpg`"
          :alt="productData.productName"
        />
        <table>
          <tr>
            <th colspan="2" class="title" style>商品詳細</th>
          </tr>
          <tr>
            <th>商品名</th>
            <td>{{ productData.productTitleName }}</td>
          </tr>
          <tr>
            <th>原材料名</th>
            <td v-html="productData.productMaterials" class="material"></td>
          </tr>
          <tr>
            <th>内容量</th>
            <td v-if="categoryId === 'tea'">5セット入り・10セット入り</td>
            <td
              v-else-if="
                (productData.productId === '11') & (categoryId === 'nuts')
              "
            >
              300g・500g
            </td>
            <td v-else>300g・500g・800g</td>
          </tr>
          <tr>
            <th>賞味期限</th>
            <td v-if="categoryId === 'fruits'">180日</td>
            <td v-else-if="categoryId === 'nuts'">150日</td>
            <td v-else-if="categoryId === 'tea'">300日</td>
          </tr>
          <tr>
            <th>保存方法</th>
            <td>
              直射日光・高温多湿を避け、冷暗所にて密閉保存ください。
              開封後はどうぞお早めにお召上がりください。
            </td>
          </tr>
          <tr v-if="productData.productOrigin !== ''">
            <th>原産国名</th>
            <td>{{ productData.productOrigin }}</td>
          </tr>
          <tr>
            <th>販売者</th>
            <td>
              株式会社高正商店<span style="margin-left: 10px"
                >名古屋市中川区伏屋五丁目502-2</span
              >
            </td>
          </tr>
        </table>
        <div class="button-cover">
          <div class="button-box">
            <a
              :href="productData.productRakutenUrl"
              target="_blank"
              class="button"
              >楽天市場で購入する</a
            >
          </div>
          <div class="button-box">
            <a
              :href="productData.productAmazonUrl"
              target="_blank"
              class="button"
              >Amazonで購入する</a
            >
          </div>
          <div class="button-box">
            <a
              :href="productData.productAupayUrl"
              target="_blank"
              class="button"
              >auPayマーケットで購入する</a
            >
          </div>
        </div>
      </div>

      <CategoryPopular />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryPopular from "@/components/Category/Popular.vue";

export default {
  name: "category-product",
  components: {
    CategoryPopular,
  },
  props: {
    categoryId: String,
    productId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    productData() {
      return this.$store.getters.productData(this.categoryId, this.productId);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  padding-top: 60px;
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }

  .product-title {
    background-color: var(--brown);
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    @media screen and (max-width: 767px) {
      height: 50px;
    }

    h1 {
      color: white;
      font-size: 28px;
      @media screen and (max-width: 767px) {
        font-size: 25px;
      }
    }
  }
  .cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  table {
    width: 80%;
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
    }

    th,
    td {
      border: solid 1px;
      padding: 10px 0;
      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    th {
      width: 20%;
      background-color: var(--beige);
      font-weight: bold;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    td {
      padding-left: 10px;
    }

    .title {
      background-color: var(--orange);
      color: white;
      border: solid 1px var(--brown);
      font-size: 20px;
    }
    /deep/.material {
      h4 {
        font-weight: bold;
      }
      p {
        padding-bottom: 13px;
      }
    }
  }
  .button-cover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    margin: 30px 0;
    .button-box {
      .button {
        display: inline-block;
        width: 250px;
        height: 34px;
        text-align: center;
        text-decoration: none;
        line-height: 33px;
        color: var(--orange);
        border: solid 1px var(--orange);
      }
      .button::before,
      .button::after {
        position: absolute;
        z-index: -1;
        display: block;
        content: "";
      }
      .button,
      .button::before,
      .button::after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
      }
      .button:hover {
        background-color: var(--orange);
        color: white;
      }
    }
  }
}
</style>