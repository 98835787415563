<template>
  <div class="contact">

    <div class="wrap space">
    <ContactForm />
    </div>
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapGetters } from "vuex";
import ContactForm from "@/components/Contact/ContactForm.vue";
export default {
  name: "Contact",
  components: {
    ContactForm,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
.space{
  padding: 100px 0 150px 0;
  @media screen and (max-width: 767px){
padding-top: 30px;
padding-bottom: 50px;
                }
}
</style>
