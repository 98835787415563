<template>
  <div class="home-aboutus">
   
   
    <h2>About us</h2>
   <UiTitleLine />
   <h1>高正商店について</h1>

<div class="takamasa-image">
  <img  
    :src="`${rootUrl}/img/takamasa_top001.jpg`" alt="高正商店出荷のようす" ><img  
    :src="`${rootUrl}/img/takamasa_top002.jpg`" alt="高正商店のナッツ" ><img  
    :src="`${rootUrl}/img/takamasa_top003.jpg`" alt="高正商店のフルーツティー" >
</div>
<p>高正商店は、おいしくてお値打ちなドライフルーツ・ナッツ・フルーツティーを販売しています。お客様からご注文をいただいてから、提携工場で人の手で一品ずつ手作業で製造した商品を新鮮な状態でお届けしています。日本の主要ショッピングモール（楽天市場・Amazon・auPAYマーケット）に、豊富な商品ラインナップで出店しています。</p>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTitleLine from "@/components/Ui/TitleLine.vue";

export default {
  name: "Home-Aboutus",
  components: {
    UiTitleLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>

.takamasa-image{
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  @media screen and (max-width: 767px){
    display: block;
  }

  img{
  width: 31%;
  align-self: flex-start;
  @media screen and (max-width: 767px){
    width: 33%;
    
  }
  }
}

</style>
