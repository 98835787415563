<template>
  <div class="layout-header">
    <div class="header">
      <div class="header-left">
        <div class="to-top" @click="top">
          <img
            :src="`${rootUrl}/img/takamasa_top_logo.png`"
            alt="高正商店ロゴ"
            class="logo"
          />
        </div>
      </div>

      <div class="header-right">
        <div class="mail-contact">
          <router-link to="/contact">
            <img :src="`${rootUrl}/img/mail.png`" alt="メール問い合わせ" />
            <p>お問い合わせ</p></router-link>
        </div>
        <div class="hamburger">
          <UiDrawer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {
    UiDrawer,
  },
  props: {
    // path: String,
  },
  data() {
    return {
      // scrollY: 0,
      // scrollTimer: null
    };
  },

  computed: {
    ...mapGetters(["rootUrl"]),
  },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  methods: {
    // handleScroll() {
    //   if (this.scrollTimer === null) {
    //     this.scrollTimer = setTimeout(
    //       function () {
    //         this.scrollY = window.scrollY;
    //         clearTimeout(this.scrollTimer);
    //         this.scrollTimer = null;
    //         // console.log(this.scrollY);
    //       }.bind(this),
    //       200
    //     );
    //   }
    // },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  position: fixed;
  z-index: 999;
  background-color: white;

  @media screen and (max-width: 767px) {
    height: 60px;
  }

  .header-left {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 270px;
      margin-left: 30px;
      margin: 5px;

      @media screen and (max-width: 767px) {
        width: 162px;
        padding-top: 0;
      }
    }

    .to-top {
      cursor: pointer;
    }
    .to-top:hover {
      opacity: 0.8;
    }
  }

  .header-right {
    width: 490px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (min-width: 768px) and(max-width: 1024px) {
      width: 510px;
    }

    @media screen and (max-width: 767px) {
      width: 70%;
    }

    .mail-contact {
      width: 170px;

      @media screen and (max-width: 767px) {
        display: none;
      }

      a {
        text-decoration: none;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 33px;
        margin: 0;
        display: block;
      }

      p {
        color: var(--brown);
        font-size: 13px;
      }
    }

    .mail-contact:hover {
      opacity: 0.5;
    }

    .hamburger {
      margin-right: 50px;
      @media screen and (max-width: 767px){
        margin-right: 15px;
      }
    }
  }
}
</style>
