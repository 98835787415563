<template>
  <div class="contact-contactform">
    <h2>Contact Us</h2>
    <UiTitleLine />
    <h3>お問い合わせ</h3>

    <div class="cover">
      <div class="title">
        <div v-if="pageType === 0">
          <!-- <h3>
            お問い合わせフォームからのご応募・お問い合わせは24時間受付しています。<br />
            ※当お問い合わせフォームは求人専用です。
          </h3> -->
        </div>
        <div v-else-if="pageType === 1">
          <h3>
            入力内容にお間違いがないかご確認の上、「送信」ボタンを押して下さい。
          </h3>
        </div>
      </div>
      <div class="form" v-if="pageType === 0 || pageType === 1">
        <table>
          <tr>
            <th>企業名・屋号<span>(任意)</span></th>
            <td>
              <div v-if="pageType === 0">
                <input
                  v-model="form.companyName"
                  type="text"
                  class="row1"
                  placeholder="※個人の場合 “なし”とご記入ください"
                />
              </div>
              <div v-else-if="pageType === 1">{{ form.companyName }}</div>
            </td>
          </tr>

          <tr>
            <th>お名前<span>(必須)</span></th>
            <td>
              <div v-if="pageType === 0">
                <input
                  v-model="form.name"
                  type="text"
                  class="row1"
                  placeholder="例）山田 太郎"
                />
              </div>
              <div v-else-if="pageType === 1">{{ form.name }}</div>
            </td>
          </tr>
          <tr>
            <th>メールアドレス<span>(必須)</span></th>
            <td>
              <div v-if="pageType === 0">
                <input
                  v-model="form.mail"
                  type="email"
                  class="row1"
                  placeholder="例）sample@gmail.com"
                />
              </div>
              <div v-else-if="pageType === 1">{{ form.mail }}</div>
            </td>
          </tr>
          <tr>
            <th class="des">
              <div class="last-row" style="font-size:18px;">お問い合わせ内容<span>(必須)</span></div>
            </th>
            <td>
              <div v-if="pageType === 0">
                <textarea
                  v-model="form.description"
                  placeholder="こちらにお問い合わせ内容を入力してください。"
                ></textarea>
              </div>
              <div v-else-if="pageType === 1">{{ form.description }}</div>
            </td>
          </tr>
        </table>
      </div>

      <div @click="confirm" class="button" v-if="pageType === 0">
        <div><p>確認する</p></div>
      </div>
      <div class="soushin" v-if="pageType === 1">
        <div @click="back" class="button"><p>修正する</p></div>
        <div @click="send" class="button"><p>送信</p></div>
      </div>
      <div v-else-if="pageType === 2" class="complete">
        <h2 style="margin-bottom: 50px">お問い合わせを承りました</h2>
        <p>
          お問い合わせを承りました。<br />お問い合わせ内容を、ご入力のメールアドレスに送信しました。20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
        </p>
        <router-link to="/">
          <div class="button2">トップに戻る</div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTitleLine from "@/components/Ui/TitleLine.vue";

export default {
  name: "ContactForm",
  components: {
    UiTitleLine,
  },
  props: {
    type: Number,
  },
  data() {
    return {
      pageType: 0,
      form: {
        name: "",
        companyName: "",
        mail: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {
    confirm() {
      // alert(this.form.companyName);
      // console.log(`company name: ${this.form.companyName}`);
      // console.log(`company name: ${this.form}`);//※ダメなやつ

      console.log(this.form);

      let validation_ok = true;
      let validation_message = "";
      console.log(validation_ok);
      console.log(validation_message);

      // 電話番号チェック
      // if (this.form.tel !== "") {
      //   console.log("電話番号 ok");
      // } else {
      //   validation_ok = false;
      //   validation_message = validation_message + "「電話番号」";
      // }

      //お名前チェック
      if (this.form.name !== "") {
        console.log("お名前 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お名前」";
      }
      //メールアドレスチェック
      if (this.form.mail !== "") {
        var mailAddress = this.form.mail;
        var hotResult = mailAddress.indexOf("hotmail.co.jp");
        var outResult = mailAddress.indexOf("outlook.jp");

        if (
          mailAddress.match(
            /[a-zA-Z0-9]+[a-zA-Z0-9_-]*@[a-zA-Z0-9_-]+[a-zA-Z0-9_-]+/)){
              //noaction
        } else {
          validation_ok = false;
          alert(`正しいメールアドレスを入力してください`);
          return;
        }

        // hotmail,outlookチェック
        if (hotResult === -1 && outResult === -1) {
          //含まれていない
        } else {
          validation_ok = false;
          alert(`hotmail. outlookは使えません`);
          return;
        }

        console.log("メールアドレス ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「メールアドレス」";
      }
      //内容チェック
      if (this.form.description !== "") {
        console.log("お問い合わせ内容 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ内容」";
      }

      //結果発表
      if (validation_ok === true) {
        this.pageType = 1;
        return;
      } else {
        alert(`${validation_message}を入力してください`);
        return;
      }
    },
    send() {
      console.log(this.form);
      // return; //■■■■■■■■■アップ前にこの行削除↓復活■■■■■■■■■■■■
      let params = new URLSearchParams();
      params.append("company_name", this.form.companyName);
      params.append("name", this.form.name);
      // params.append("tel", this.form.tel);
      params.append("mail", this.form.mail);
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          if (response.data.status === 200) {
            // console.log('ok')
            this.pageType = 2;
            return;
          } else if (
            response.data.status === 400 ||
            response.data.status === 500
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      alert("本当に戻りますか？");
      this.pageType = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    width: 90%;
  }

  .title {
    padding-bottom: 30px;
    padding-left: 0;

    @media screen and (max-width: 767px) {
      padding-bottom: 5px;
    }

    h3 {
      padding-top: 15px;
      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }
    }
  }

  .form {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    table {
      table-layout: fixed;
      width: 100%;
      th,
      td {
        border: solid 1px var(--brown);
        vertical-align: middle !important;
      }

      th {
        width: 25%;
        text-align: center;
        padding: 20px;
        font-size: 20px;
        font-weight: bold;
        @media screen and (max-width: 767px){
          text-align: left;
          padding: 0!important;
        }

        span {
          font-size: 13px;
          margin-left: 5px;
        }

        .des {
          padding: 0 0 0 20px;

          @media screen and (max-width: 767px) {
            padding: 0;
          }

          .last-row {
            display: flex;
            align-items: center;
            font-size: 18px;
          }

          span {
            font-size: 12px;
          }
        }
      }
      td {
        padding-left: 10px;
        @media screen and (max-width: 767px){
          border: solid 1px var(--brown)!important;
        }

        .div {
          word-break: break-all;
        }
      }
      .input,
      .textarea {
        width: 100%;
      }
      .input {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 767px) {
        tr,
        th,
        td {
          display: block;
          width: auto;
        }

        th {
          border: none;
          width: 100%;
          font-weight: bold;
          margin-bottom: 10px;
          padding: 10px 20px;
          margin-top: 25px;
        }

        td {
          border: none;
          width: 100%;
          padding-left: 0;

          .last-row {
            padding-left: 0;
          }
          .input,
          .textarea {
            border: solid 1px;
          }
        }
      }
    }
  }
  .button,
  .button2 {
    border: solid 1px var(--brown);
    padding: 5px 35px;
    width: 150px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: 0.4s;

    @media screen and (max-width: 767px) {
      width: 50%;
      padding: 5px 10px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      background-color: var(--brown);
      color: white;
    }
  }

  .row1,
  textarea {
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    border: solid 0.5px var(--glay2);
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    column-count: initial !important;
    word-break: break-all;
  }

  .soushin {
    display: flex;
    justify-content: space-between;
    .button {
      width: 150px;
      @media screen and (max-width: 767px) {
      }
      width: 40%;
    }
  }

  textarea {
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-rendering: auto;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .sp {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .complete {
    text-align: left;

    a {
      text-decoration: none;
      color: black;

      .button2 {
        width: 170px;
        margin-top: 70px;
        margin-bottom: 50px;
        border: solid 1px var(--brown);
      }
    }
  }
}
</style>
