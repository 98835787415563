<template>
  <div id="app">
    <Header />
    <router-view class="body-top" />
    <ToTopButton :path="path" />
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
:root {
  --orange: rgb(224, 146, 0);
  --beige: rgb(228, 213, 174);
  --brown: rgb(78, 7, 11);
}
#app {
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体",
    "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: 16px;
  color: rgb(78, 7, 11);
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  // -webkit-backface-visibility: hidden;
}

h1,
h2,
h3 {
  text-align: center;
  font-weight: bold;
}
h1,
h3 {
  font-size: 20px;
}
h2 {
  font-size: 43px;
}
p {
  line-height: 1.8em;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1150px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2em;

  @media screen and (max-width: 1150px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sp-kaigyo {
  display: none;

  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}

.pc-kaigyo {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.body-top {
  padding-top: 70px;
}
</style>
