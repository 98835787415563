<template>
  <div class="category-home">
    <div class="wrap">
      <h2>{{ categoryData.categoryLabel }}</h2>
      <UiTitleLine />
      <h3>{{ categoryData.categoryName }} 商品一覧</h3>

      <div class="product-cover">
        <div
          class="product-box"
          v-for="productData in categoryData.productList"
          :key="productData.productId"
        >
          <img
            :src="`${rootUrl}/img/takamasa-${categoryData.categoryId}-${productData.productId}.jpg`"
            :alt="productData.productName"
          />
          <p>{{ productData.productName }}</p>
          <router-link
            :to="`/category/${categoryData.categoryId}/product/${productData.productId}`"
            class="button"
          >
            商品詳細を見てみる</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTitleLine from "@/components/Ui/TitleLine.vue";

export default {
  name: "category-home",
  components: {
    UiTitleLine,
  },
  props: {
    categoryId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    categoryData() {
      return this.$store.getters.categoryData(this.categoryId);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  padding: 100px 0px;

      @media screen and (max-width: 1150px) {
    padding-left: 20px;
    padding-right: 20px;
}
}
.product-cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  @media screen and (max-width: 767px){
    flex-direction: column;
    padding: 20px 0;
  }
}
.product-box {
  width: 48% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  @media screen and (max-width: 767px){
    width: 100%!important;
  }
  p {
    padding: 10px 0;
    font-weight: bold;
    font-size: 20px;
  }
}
.button {
  display: inline-block;
  width: 200px;
  height: 34px;
  text-align: center;
  text-decoration: none;
  line-height: 33px;
  color: var(--orange);
  border: solid 1px var(--orange);
}
.button::before,
.button::after {
  position: absolute;
  z-index: -1;
  display: block;
  content: "";
}
.button,
.button::before,
.button::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.button:hover {
  background-color: var(--orange);
  color: white;
}
</style>
