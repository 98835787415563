<template>
  <div
    class="layout-footer">
   
      <div class="copyright">
        <p>Copyright © takamasa-shoten All Rights Reserved.</p>
      </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  data() {
    return {};
  },
  computed: {
  ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.layout-footer{
  background-color: var(--brown);
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
   @media screen and (max-width: 767px) {
     align-items: center;
   }

.copyright {
  font-size: 13px;
  color: white;
  margin-right: 200px;
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
    }
}

</style>
