<template>
  <div class="category">
    <router-view :categoryId="categoryId"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Category",
  components: {},
  props: {
    categoryId: String,
    
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>
<style lang="scss" scoped>
</style>
