<template>
  <div class="home">


<img  
    :src="`${rootUrl}/img/takamasa_top_image.jpg`" alt="高正商店トップ"
    class="pc-top-img" >
<img  
    :src="`${rootUrl}/img/SP001.jpg`" alt="高正商店SPトップ"
    class="sp-top-img" >

<div class="wrap space-top">
<HomeAboutus />
</div>

<div class="wrap space-top">
<HomeCategory />
</div>

<div class="space">
<HomeQuality />
</div>

<div class="wrap space2">
<HomeShopping />
</div>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeAboutus from "@/components/Home/Aboutus.vue";
import HomeCategory from "@/components/Home/Category.vue";
import HomeQuality from "@/components/Home/Quality.vue";
import HomeShopping from "@/components/Home/Shopping.vue";

export default {
  name: 'Home',
  components: {
    HomeAboutus,
    HomeCategory,
    HomeQuality,
    HomeShopping,
},
    data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
}
</script>
<style lang="scss" scoped>
.space{
  padding: 30px 0;
  @media screen and (max-width: 767px){
    padding-top: 70px;
    padding-bottom: 40px;
  }
}
.space2{
  padding: 120px 0;
    @media screen and (max-width: 767px){
    padding-top: 70px;
    padding-bottom: 40px;
  }
}
.space-top{
  padding-top: 150px;
  @media screen and (max-width: 767px){
    padding-top: 80px;
  }
}
/deep/.wrap{
    @media screen and (max-width: 1150px) {
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
}
.pc-top-img{
@media screen and (max-width: 767px){
  display: none;
}
}

.sp-top-img{
display: none;
@media screen and (max-width: 767px){
  display: initial;
}
}

</style>
