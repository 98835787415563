import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'


import Home from '../views/Home.vue'
import Category from '../views/Category.vue'
import CategoryProduct from '../views/Category/Product.vue'
import CategoryHome from '../views/Category/Home.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '高正商店｜ドライフルーツ・ナッツ・フルーツティーの販売',
      description: '株式会社高正商店の公式ホームページです。高正商店では、おいしくてお値打ちなドライフルーツ・ナッツ・フルーツティーの販売をしています。楽天市場・Amazon・auPAYマーケットに出店中です。'
    }
  },
  {
    path: '/category/:categoryId',
    name: 'category',
    component: Category,
    props: route => ({
      categoryId: String(route.params.categoryId)
    }),
    meta: {
      title: '商品カテゴリ名｜高正商店',
      description: '高正商店で取り扱う"商品カテゴリ名"の商品一覧です。'
      //最後に確認
    },
    children: [
      {
        path: '',
        name: 'category-home',
        component: CategoryHome,
        meta: {
          title: `カテゴリ一覧`,
          description: ''
        }
      },
      {
        path: 'product/:productId',
        name: 'category-product',
        component: CategoryProduct,
        props: route => ({
          productId: String(route.params.productId)
        }),
        meta: {
          title: `カテゴリ詳細`,
          description: ''
        }
      },
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'お問い合わせ｜高正商店',
      description: '高正商店のお問い合わせページです。'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  let metaTitle = '';
  let metaDescription = '';
  switch (to.name) {
    case 'category-home':
      metaTitle = store.getters.categoryData(to.params.categoryId).categoryName+"｜高正商店";
      metaDescription = "高正商店で取り扱う"+store.getters.categoryData(to.params.categoryId).categoryName+"の商品一覧です。";
      break;
      case 'category-product':
      metaTitle = store.getters.productData(to.params.categoryId, to.params.productId).productName+"｜高正商店";
      metaDescription = "高正商店の"+store.getters.productData(to.params.categoryId, to.params.productId).productName+"です。Amazon・楽天市場・auPAYマーケットなどで送料無料でご購入いただけます。";
      break;
  
    default:
      metaTitle = to.meta.title;
      metaDescription = to.meta.description;
      break;
  }
  setTitle(metaTitle);
  setDescription(metaDescription);

  store.dispatch("setRootUrl");
  store.dispatch("setCategoryList");

  // let changeWindowSize = 375;
  // let viewportContent = '';
  // if (window.innerWidth < changeWindowSize) {
  //   viewportContent = 'width=375';
  // }else {
  //   viewportContent = 'width=1300';
  // }
  // document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent);

  next();

});

export default router
