<template>
  <div class="home-category">
    <h2>Product</h2>
    <UiTitleLine />
    <h3>取扱商品</h3>

    <div class="category-outer">
      <div class="category-box">
     
          <img
            :src="`${rootUrl}/img/takamasa_top004.jpg`"
            alt="ドライフルーツ"
          />

        <div class="content">
          <div class="content-inner">
            <p class="sub-title">ドライフルーツ</p>
            <p class="detail">
              セブ産ドライマンゴー、ドライあんず（アプリコット）、大粒ドライいちじくなどの人気商品を筆頭に、10種類以上のドライフルーツを取り扱いしています。
            </p>
            <div class="button-cover">
              <router-link to="/category/fruits"
                ><div class="button">
                  <img :src="`${rootUrl}/img/triangle.png`" alt="三角" />
                  <span>商品一覧を見てみる</span>
                </div></router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="category-box">

          <img :src="`${rootUrl}/img/takamasa_top005.jpg`" alt="ナッツ" />

        <div class="content">
          <div class="content-inner">
            <p class="sub-title">ナッツ</p>
            <p class="detail">
              こだわってローストした素焼き、食べだしたら止まらなくなる塩付、人気ナッツを一度に味わえるミックスなど豊富なラインナップをご用意しております。
            </p>
            <div class="button-cover">
              <router-link to="/category/nuts"
                ><div class="button">
                  <img :src="`${rootUrl}/img/triangle.png`" alt="三角" />
                  <span>商品一覧を見てみる</span>
                </div></router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="category-box">

          <img
            :src="`${rootUrl}/img/takamasa_top006.jpg`"
            alt="フルーツティー"
          />

        <div class="content">
          <div class="content-inner">
            <p class="sub-title">フルーツティー</p>
            <p class="detail">
              高正商店自慢のドライフルーツがそのまま入った食べるフルーツティーです。お好みにあわせて６種類よりお選びいただけます。5種類(各１個または２個)セットもご用意しております。
            </p>
            <div class="button-cover">
              <router-link to="/category/tea"
                ><div class="button">
                  <img :src="`${rootUrl}/img/triangle.png`" alt="三角" />
                  <span>商品一覧を見てみる</span>
                </div></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popular">
      <div class="popular-box">
        <div class="tag">
          <img :src="`${rootUrl}/img/takamasa_top003.png`" alt="人気商品紹介" />
        </div>
        <div class="popular-inner">
          <div class="popular-img-cover">
            <img
              :src="`${rootUrl}/img/takamasa_top007.jpg`"
              alt="7種ミックス"
            />
            <img
              :src="`${rootUrl}/img/takamasa_top008.jpg`"
              alt="7種ミックス"
            />
          </div>
          <div class="popular-content">
            <p class="sub-title">7種ミックスナッツ</p>
            <p class="detail">
              どのナッツも偏りなくこだわって配合した、高正商店人気ナンバーワンの7種ミックスナッツです。大粒で新鮮、そして美味しいと思ったものだけを厳選しております。マカダミアやピーカンナッツが入っていることも人気の理由のひとつです。素材の味が堪能できるように、完全無添加で仕上げておりますので、安心してお使いただけます。
            </p>
            <div class="button-cover">
              <a
                href="https://item.rakuten.co.jp/takamasa-shoten/7nutsmix-08/"
                class="button"
                target="_blank"
              >
                楽天市場で購入する</a
              >
              <a
                href="https://www.amazon.co.jp/%E3%83%9F%E3%83%83%E3%82%AF%E3%82%B9%E3%83%8A%E3%83%83%E3%83%84-800%EF%BD%87-%E3%82%A2%E3%83%BC%E3%83%A2%E3%83%B3%E3%83%89-%E3%83%9E%E3%82%AB%E3%83%80%E3%83%9F%E3%82%A2-%E3%83%94%E3%82%B9%E3%82%BF%E3%83%81%E3%82%AA/dp/B08GKCXW86/ref=sr_1_2?__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&dchild=1&keywords=%E9%AB%98%E6%AD%A3%E5%95%86%E5%BA%97&qid=1634702611&sr=8-2"
                class="button"
                target="_blank"
              >
                Amazonで購入する
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="popular-box">
        <div class="popular-inner">
          <div class="popular-img-cover">
            <img
              :src="`${rootUrl}/img/takamasa_top009.jpg`"
              alt="ドライあんず"
            />
            <img
              :src="`${rootUrl}/img/takamasa_top010.jpg`"
              alt="ドライあんず"
            />
          </div>
          <div class="popular-content">
            <p class="sub-title">ドライあんず(アプリコット)</p>
            <p class="detail">
              しっとりしていて、程よい爽やかな自然な甘味が特徴です。また、厚めの果肉なのでしっかりとした食感も味わえます。ノンオイルで砂糖や添加物なども一切使用しておりません。そのままでももちろん美味しいですが、パンやお菓子作りの際にドライあんずを加えると、ワンランクアップするとご好評いただいております。
            </p>
            <div class="button-cover">
              <a
                href="https://item.rakuten.co.jp/takamasa-shoten/apricot-08/"
                class="button"
                target="_blank"
              >
                楽天市場で購入する</a
              >
              <a
                href="https://www.amazon.co.jp/%E3%82%A2%E3%83%97%E3%83%AA%E3%82%B3%E3%83%83%E3%83%88-800g-%E3%83%88%E3%83%AB%E3%82%B3%E7%94%A3-%E7%A0%82%E7%B3%96%E4%B8%8D%E4%BD%BF%E7%94%A8-%E7%BE%8E%E5%AE%B9%E6%88%90%E5%88%86/dp/B08GKDCKGZ/ref=sr_1_13?dchild=1&m=AD4TGQZU6W7ZQ&marketplaceID=A1VC38T7YXB528&qid=1634702722&s=merchant-items&sr=1-13"
                class="button"
                target="_blank"
              >
                Amazonで購入する
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="popular-box">
        <div class="popular-inner">
          <div class="popular-img-cover">
            <img
              :src="`${rootUrl}/img/takamasa_top011.jpg`"
              alt="フルーツティー5種セット"
            />
            <img
              :src="`${rootUrl}/img/takamasa_top012.jpg`"
              alt="フルーツティー5種セット"
            />
          </div>
          <div class="popular-content">
            <p class="sub-title">フルーツティー5種セット</p>
            <p class="detail">
              ドライフルーツをまるごと詰め込んだフルーツティーを一度にお楽しみいただける５種セットです。見た目もかわいいフルーツハーブティー、芯から温まるジンジャーアッサムティーなどバラエティー豊かな５種類となっております。あなたのお気に入りの１杯をぜひ見つけてみてください。
            </p>
            <div class="button-cover">
              <a
                href="https://item.rakuten.co.jp/takamasa-shoten/5series-10/"
                class="button"
                target="_blank"
              >
                楽天市場で購入する</a
              >
              <a
                href="https://www.amazon.co.jp/%E3%83%95%E3%83%AB%E3%83%BC%E3%83%84%E3%83%86%E3%82%A3%E3%83%BC-%EF%BC%95%E7%A8%AE%E3%82%BB%E3%83%83%E3%83%88%C3%97%EF%BC%92%E8%A2%8B%EF%BC%88%E5%90%84%EF%BC%92%E3%82%B1%E3%80%81%E8%A8%8810%E3%82%B1%E5%85%A5%E3%82%8A%EF%BC%89-%E3%82%A2%E3%83%83%E3%82%B5%E3%83%A0%E3%83%86%E3%82%A3%E3%83%BC-%E3%82%B8%E3%83%B3%E3%82%B8%E3%83%A3%E3%83%BC-%E3%83%95%E3%83%AB%E3%83%BC%E3%83%84%E3%83%8F%E3%83%BC%E3%83%96%E3%83%86%E3%82%A3%E3%83%BC/dp/B08KJ25M1K/ref=sr_1_21?dchild=1&m=AD4TGQZU6W7ZQ&marketplaceID=A1VC38T7YXB528&qid=1634702753&s=merchant-items&sr=1-21"
                class="button"
                target="_blank"
              >
                Amazonで購入する
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTitleLine from "@/components/Ui/TitleLine.vue";

export default {
  name: "HomeCategory",
  components: {
    UiTitleLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-category {
  .category-outer {
    .category-box {
      display: flex;
      justify-content: flex-start;
      margin-top: 40px;

      @media screen and (max-width: 767px){
        display: block;
      }
      img {
        width: 62%;
        object-fit:contain;
        align-self: flex-start;
        @media screen and (max-width: 767px){
          width: 100%;
          vertical-align: bottom;
        }
      }
      .content {
        width: 40%;
        display: flex;
        align-items: center;
        background-color: var(--beige);
        @media screen and (max-width: 767px){
          width: 100%;
        }

        .content-inner {
          margin: 20px 30px;

          .sub-title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
          }

          .detail {
            margin-bottom: 30px;
          }

          .button-cover {
            display: flex;
            align-items: center;

            a {
              text-decoration: none;
              color: var(--brown);
              padding: 0 5px;
              box-shadow: inset 0 0 0 0 rgb(224, 209, 180);
              transition: all 1s ease-in-out 0s;
            }

            a:hover {
              color: var(--brown);
              //  color: white;
              box-shadow: inset 300px 0 0 0 rgb(224, 185, 112);
            }
            span {
              vertical-align: middle;
            }
            img {
              width: 16px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .popular {
    margin: 180px 0;
    @media screen and (max-width: 767px){
      margin-bottom: 80px;
    }
    .popular-box {
      position: relative;
      padding: 50px 0;
      border-top: 2px solid var(--brown);
      @media screen and (max-width: 767px) {
        padding: 30px 0;
      }

      &:last-child {
        border-bottom: 2px solid var(--brown);
      }
      .tag {
        margin: 0;
        img {
          position: absolute;
          bottom: 100%;
          right: 0;
          width: 240px;
        }
      }
      .popular-inner {
        display: flex;
        justify-content: space-between;
        img {
          align-self: flex-start;
          width: 29%;
        }

        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
        }
        .popular-img-cover {
          display: flex;
          justify-content: space-between;
          width: 60%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          img {
            align-self: flex-start;
            width: 48%;
          }
        }
        .popular-content {
          width: 40%;
          margin-left: 20px;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 10px;
          }

          .sub-title {
            font-size: 20px;
            font-weight: bold;
            vertical-align: top;
            margin-bottom: 3px;
            @media screen and (max-width: 767px) {
              margin-bottom: 10px;
            }
          }
          .detail {
            margin-bottom: 3px;
            @media screen and (max-width: 767px) {
              margin-bottom: 15px;
            }
          }

          .button-cover {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 767px){
              flex-direction: column;
              align-items: center;
              margin: 10px 0;
                }

            .button {
              display: inline-block;
              width: 48%;
              height: 34px;
              text-align: center;
              text-decoration: none;
              line-height: 33px;
              color: var(--orange);
              border: solid 1px var(--orange);
               @media screen and (min-width:768px) and ( max-width:1024px){
                 font-size: 14px;
               }
                
                @media screen and (max-width: 767px){
                  width: 210px;
                  margin-bottom: 10px;

                }
             
            }
            .button::before,
            .button::after {
              position: absolute;
              z-index: -1;
              display: block;
              content: "";
            }
            .button,
            .button::before,
            .button::after {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
            .button:hover {
              background-color: var(--orange);
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
