<template>
  <div class="home-shopping">
    <h2>Shopping</h2>
    <UiTitleLine />
    <h3>商品購入・出店中のモール紹介</h3>

    <div class="shopping-image">
      <div class="button-cover">
        <a href="https://www.rakuten.co.jp/takamasa-shoten/" target="_blank">
          <div class="shopping-button">
            <img :src="`${rootUrl}/img/takamasa_top002-2.png`" alt="楽天ロゴ" />
          </div>
        </a>
      </div>
      <div class="button-cover">
        <a
          href="https://www.amazon.co.jp/s?me=AD4TGQZU6W7ZQ&marketplaceID=A1VC38T7YXB528"
          target="_blank"
        >
          <div class="shopping-button">
            <img
              :src="`${rootUrl}/img/takamasa_top003-2.png`"
              alt="Amazonロゴ"
            />
          </div>
        </a>
      </div>
      <div class="button-cover">
        <a href="https://wowma.jp/user/62696240/" target="_blank">
          <div class="shopping-button">
            <img
              :src="`${rootUrl}/img/takamasa_top004-2.png`"
              alt="aupayロゴ"
            />
          </div>
        </a>
      </div>
    </div>
    <p>
      高正商店が出店している各モールで、高正商店の商品を購入することができます。すでに会員登録済のモールや、ポイントがほしいモールで、ぜひ高正商店の商品をお試しください。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTitleLine from "@/components/Ui/TitleLine.vue";

export default {
  name: "Home-Aboutus",
  components: {
    UiTitleLine,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.shopping-image {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;

  @media screen and (max-width: 767px){
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }

  .button-cover {
    width: 30%;
    padding: 5px 0;
    border: solid 1px gray;
    text-align: center;

    @media screen and (max-width: 767px){
      width: 70%;
      padding:0;
      margin: 10px 0;
    }

    &:hover {
      // background-color:rgba(224 ,210 ,180, 0.2);
      border: solid 1px var(--beige);
      border: solid 1px black;
    }
  }

  img {
    width: 90%;
    vertical-align: middle;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

    &:hover {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  }
}
</style>
