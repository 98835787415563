<template>
  <div class="ui-title-line">
<div class="brown-line"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTitleLine",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.brown-line{
border-bottom: solid 1px var(--brown);
margin: 24px auto 16px auto;
width: 78px;
}

</style>
