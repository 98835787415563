<template>
  <div
    class="category-popular">
<div class="popular">
      <div class="popular-box">
            <img
            :src="`${rootUrl}/img/takamasa_top003.png`"
            alt="人気商品紹介"
            class="tag"
          />
          <h3>ドライフルーツ</h3>
        <div class="popular-inner">
          <div class="popular-item">
          <img
            :src="`${rootUrl}/img/takamasa-fruits-1.jpg`"
            alt="ドライフルーツ"
          />
          <p>ドライあんず</p>
          <router-link to="/category/fruits/product/1" class="button">
                商品詳細を見てみる</router-link
              >
          </div>
          <div class="popular-item">
          <img
            :src="`${rootUrl}/img/takamasa-fruits-2.jpg`"
            alt="ドライクランベリー"
          />
          <p>ドライクランベリー（ハーフ）</p>
          <router-link to="/category/fruits/product/2" class="button">
                商品詳細を見てみる</router-link
              >
          </div>
        </div>
      </div>
      <div class="popular-box">
        <h3>ナッツ</h3>
        <div class="popular-inner">
          <div class="popular-item">
          <img
            :src="`${rootUrl}/img/takamasa-nuts-1.jpg`"
            alt="7種ミックスナッツ"
          />
          <p>７種ミックスナッツ</p>
          <router-link to="/category/nuts/product/1" class="button">
                商品詳細を見てみる</router-link
              >
          </div>
          <div class="popular-item">
          <img
            :src="`${rootUrl}/img/takamasa-nuts-2.jpg`"
            alt="２種ミックスナッツ"
          />
          <p>２種ミックスナッツ</p>
          <router-link to="/category/nuts/product/2" class="button">
                商品詳細を見てみる</router-link
              >
          </div>
        </div>
      </div>
      <div class="popular-box">
          <h3>フルーツティー</h3>
        <div class="popular-inner">
          <div class="popular-item">
          <img
            :src="`${rootUrl}/img/takamasa-tea-1.jpg`"
            alt="フルーツティー５種セット"
          />
          <p>フルーツティー５種セット</p>
          <router-link to="/category/tea/product/1" class="button">
                商品詳細を見てみる</router-link
              >
          </div>
          <div class="popular-item">
          <img
            :src="`${rootUrl}/img/takamasa-tea-2.jpg`"
            alt="八宝茶"
          />
          <p>八宝茶</p>
          <router-link to="/category/tea/product/2" class="button">
                商品詳細を見てみる</router-link
              >
          </div>
        </div>
      </div>
    </div>  


    </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CategoryPopular",
  components: {},
  data() {
    return {};
  },
  computed: {
  ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.popular {
    margin: 100px 0;

    
    .popular-box {
      position: relative;
      padding: 50px 0;
      border-top: 2px solid var(--brown);
      
      &:last-child{
        border-bottom: 2px solid var(--brown);
      }
     .tag{
      width: 240px;
      margin: 0;
      position: absolute;
      bottom: 100%;
      right: 0;   
    } 
    h3{
margin-bottom: 30px;
font-size: 25px;
    }
      .popular-inner {
        display: flex;
        justify-content: space-between;
          @media screen and (max-width: 767px){
            flex-direction: column;
      }

        .popular-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 48%;
              @media screen and (max-width: 767px) {
              width: 100%;
              margin-bottom: 20px;
              }
          p{
            margin: 15px 0;
            font-size: 21px;
            font-weight: bold;
          }

          .button {
            display: inline-block;
              width: 250px;
              height: 34px;
              text-align: center;
              text-decoration: none;
              line-height: 33px;
              color: var(--orange);
              border: solid 1px var(--orange);


              
            }
            .button::before,
            .button::after {
              position: absolute;
              z-index: -1;
              display: block;
              content: "";
            }
            .button,
            .button::before,
            .button::after {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
            .button:hover {
              background-color: var(--orange);
              color: white;
            }
          }
        }
      }
              }



</style>
