import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl: 'https://takamasa-shoten.com',
    categoryList: [],
  },
  getters: {
    rootUrl(state) {
      return state.rootUrl
    },
    categoryData: (state) => (categoryId) => {
      return state.categoryList.find(category => category.categoryId === categoryId);
    },
    productData: (state, getters) => (categoryId, productId) => {
      let categoryData = getters.categoryData(categoryId);
      return categoryData.productList.find(productData => productData.productId === productId);
    },

  },
  mutations: {
    setRootUrl(state, payload) {
      state.rootUrl = payload;
    },
    setCategoryList(state, payload) {
      state.categoryList = payload;
    },
  },
  actions: {
    setRootUrl({
      commit
    }) {
      let rt = process.env.NODE_ENV === 'production' ? "https://takamasa-shoten.com" : "http://takamasa-shoten.localhost";
      //↑同じ意味↓
      // let rt;
      // if(process.env.NODE_ENV === 'production') {
      //   rt = "https://aru-dry.com";
      // }else {
      //   rt = "http://aru-dry.localhost";
      // }
      commit('setRootUrl', rt);
    },
    setCategoryList({
      commit,
      getters
    }) {
      axios
        .get(`${getters.rootUrl}/api/get-categories-and-products.php`)
        .then((response) => {
          console.log(response);
          commit('setCategoryList', response.data.categoryList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
